.psv-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background: radial-gradient(#fff 0%, #fdfdfd 16%, #fbfbfb 33%, #f8f8f8 49%, #efefef 66%, #dfdfdf 82%, #bfbfbf 100%);
  overflow: hidden; }

.psv-container--fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.psv-canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: opacity linear 100ms; }

.psv-canvas {
  display: block; }

.psv-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80; }

.psv-loader {
  position: relative;
  text-align: center;
  color: rgba(61, 61, 61, 0.7);
  width: 150px;
  height: 150px;
  border: 10px solid transparent; }
  .psv-loader::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }
  .psv-loader, .psv-loader-image, .psv-loader-text {
    display: inline-block;
    vertical-align: middle; }
  .psv-loader-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .psv-loader-text {
    font: 14px sans-serif; }

.psv-navbar {
  display: flex;
  position: absolute;
  z-index: 90;
  bottom: -40px;
  left: 0;
  width: 100%;
  height: 40px;
  background: rgba(61, 61, 61, 0.5);
  transition: bottom ease-in-out .1s; }
  .psv-navbar--open {
    bottom: 0; }
  .psv-navbar,
  .psv-navbar * {
    box-sizing: content-box; }

.psv-caption {
  flex: 1 1 100%;
  color: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  text-align: center; }
  .psv-caption-icon {
    height: 20px;
    width: 20px;
    cursor: pointer; }
    .psv-caption-icon * {
      fill: rgba(255, 255, 255, 0.7); }
  .psv-caption-content {
    display: inline-block;
    padding: 10px;
    font: 16px sans-serif;
    white-space: nowrap; }

.psv-button {
  flex: 0 0 auto;
  padding: 10px;
  position: relative;
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: transparent;
  color: rgba(255, 255, 255, 0.7); }
  .psv-button--active {
    background: rgba(255, 255, 255, 0.2); }
  .psv-button--disabled {
    pointer-events: none;
    opacity: 0.5; }
  .psv-button .psv-button-svg {
    width: 100%;
    transform: scale(1);
    transition: transform 200ms ease; }

.psv-button:not(.psv-button--disabled):focus-visible {
  outline: 2px dotted currentColor;
  outline-offset: -2px; }

.psv-container:not(.psv--is-touch) .psv-button--hover-scale:not(.psv-button--disabled):hover .psv-button-svg {
  transform: scale(1.2); }

.psv-move-button + .psv-move-button {
  margin-left: -10px; }

.psv-custom-button {
  width: auto; }

.psv-autorotate-button.psv-button {
  width: 25px;
  height: 25px;
  padding: 7.5px; }

.psv-zoom-range.psv-button {
  width: 80px;
  height: 1px;
  margin: 10px 0;
  padding: 9.5px 0;
  max-width: 600px; }

.psv-zoom-range-line {
  position: relative;
  width: 80px;
  height: 1px;
  background: rgba(255, 255, 255, 0.7);
  transition: all .3s ease; }

.psv-zoom-range-handle {
  position: absolute;
  border-radius: 50%;
  top: -3px;
  width: 7px;
  height: 7px;
  background: rgba(255, 255, 255, 0.7);
  transform: scale(1);
  transition: transform .3s ease; }

.psv-zoom-range:not(.psv-button--disabled):hover .psv-zoom-range-line {
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.7); }

.psv-zoom-range:not(.psv-button--disabled):hover .psv-zoom-range-handle {
  transform: scale(1.3); }

.psv-notification {
  position: absolute;
  z-index: 100;
  bottom: -40px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0 2em;
  opacity: 0;
  transition-property: opacity, bottom;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms; }
  .psv-notification-content {
    max-width: 50em;
    background-color: rgba(61, 61, 61, 0.8);
    border-radius: 4px;
    padding: 0.5em 1em;
    font: 14px sans-serif;
    color: white; }
  .psv-notification--visible {
    opacity: 100;
    bottom: 80px; }

.psv-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 110;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: radial-gradient(#fff 0%, #fdfdfd 16%, #fbfbfb 33%, #f8f8f8 49%, #efefef 66%, #dfdfdf 82%, #bfbfbf 100%);
  color: black;
  opacity: 0.8; }
  .psv-overlay-image {
    margin-bottom: 4vh; }
    .psv-overlay-image svg {
      width: 50vw; }
      @media (orientation: landscape) {
        .psv-overlay-image svg {
          /* stylelint-disable-line scss/media-feature-value-dollar-variable */
          width: 25vw; } }
  .psv-overlay-text {
    font: 30px sans-serif;
    text-align: center; }
  .psv-overlay-subtext {
    font: 20px sans-serif;
    opacity: .8;
    text-align: center; }

/* stylelint-disable scss/no-duplicate-dollar-variables */
/* stylelint-enable */
.psv-panel {
  position: absolute;
  z-index: 90;
  right: 0;
  height: 100%;
  width: 400px;
  max-width: calc(100% - 24px);
  background: rgba(10, 10, 10, 0.7);
  transform: translate3d(100%, 0, 0);
  opacity: 0;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  transition-duration: .1s;
  cursor: default;
  margin-left: 9px; }
  .psv--has-navbar .psv-panel {
    height: calc(100% - 40px); }
  .psv-panel-close-button {
    display: none;
    position: absolute;
    top: 0;
    left: -24px;
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.9); }
    .psv-panel-close-button::before, .psv-panel-close-button::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 4px;
      width: 15px;
      height: 1px;
      background-color: #fff;
      transition: .2s ease-in-out;
      transition-property: width, left, transform; }
    .psv-panel-close-button::before {
      transform: rotate(45deg); }
    .psv-panel-close-button::after {
      transform: rotate(-45deg); }
    .psv-panel-close-button:hover::before, .psv-panel-close-button:hover::after {
      left: 0;
      width: 23px; }
    .psv-panel-close-button:hover::before {
      transform: rotate(135deg); }
    .psv-panel-close-button:hover::after {
      transform: rotate(45deg); }
  .psv-panel-resizer {
    display: none;
    position: absolute;
    top: 0;
    left: -9px;
    width: 9px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    cursor: col-resize; }
    .psv-panel-resizer::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 1px;
      margin-top: -14.5px;
      width: 1px;
      height: 1px;
      box-shadow: 1px 0 #fff, 3px 0px #fff, 5px 0px #fff, 1px 2px #fff, 3px 2px #fff, 5px 2px #fff, 1px 4px #fff, 3px 4px #fff, 5px 4px #fff, 1px 6px #fff, 3px 6px #fff, 5px 6px #fff, 1px 8px #fff, 3px 8px #fff, 5px 8px #fff, 1px 10px #fff, 3px 10px #fff, 5px 10px #fff, 1px 12px #fff, 3px 12px #fff, 5px 12px #fff, 1px 14px #fff, 3px 14px #fff, 5px 14px #fff, 1px 16px #fff, 3px 16px #fff, 5px 16px #fff, 1px 18px #fff, 3px 18px #fff, 5px 18px #fff, 1px 20px #fff, 3px 20px #fff, 5px 20px #fff, 1px 22px #fff, 3px 22px #fff, 5px 22px #fff, 1px 24px #fff, 3px 24px #fff, 5px 24px #fff, 1px 26px #fff, 3px 26px #fff, 5px 26px #fff, 1px 28px #fff, 3px 28px #fff, 5px 28px #fff;
      background: transparent; }
  .psv-panel-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    color: gainsboro;
    font: 16px sans-serif;
    overflow: auto; }
    .psv-panel-content:not(.psv-panel-content--no-margin) {
      padding: 1em; }
    .psv-panel-content--no-interaction {
      user-select: none;
      pointer-events: none; }
  .psv-panel--open {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition-duration: .2s; }
    .psv-panel--open .psv-panel-close-button,
    .psv-panel--open .psv-panel-resizer {
      display: block; }

.psv-panel-menu-title {
  display: flex;
  align-items: center;
  font: 24px sans-serif;
  margin: 24px 12px; }
  .psv-panel-menu-title svg {
    width: 24px;
    height: 24px;
    margin-right: 12px; }

.psv-panel-menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden; }

.psv-panel-menu-item {
  min-height: 20px;
  padding: 0.5em 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background .1s ease-in-out; }
  .psv-panel-menu-item-icon {
    flex: none;
    height: 20px;
    width: 20px;
    margin-right: 0.5em; }
    .psv-panel-menu-item-icon img {
      max-width: 100%;
      max-height: 100%; }
    .psv-panel-menu-item-icon svg {
      width: 100%;
      height: 100%; }
  .psv-panel-menu-item:focus-visible {
    outline: 1px dotted currentColor;
    outline-offset: -1px; }

.psv-panel-menu--stripped .psv-panel-menu-item:hover {
  background: rgba(255, 255, 255, 0.2); }

.psv-panel-menu--stripped .psv-panel-menu-item:nth-child(odd), .psv-panel-menu--stripped .psv-panel-menu-item:nth-child(odd)::before {
  background: rgba(255, 255, 255, 0.1); }

.psv-panel-menu--stripped .psv-panel-menu-item:nth-child(even), .psv-panel-menu--stripped .psv-panel-menu-item:nth-child(even)::before {
  background: transparent; }

.psv-container:not(.psv--is-touch) .psv-panel-menu-item:hover {
  background: rgba(255, 255, 255, 0.2); }

.psv-tooltip {
  position: absolute;
  z-index: 50;
  box-sizing: border-box;
  max-width: 200px;
  background-color: rgba(61, 61, 61, 0.8);
  border-radius: 4px;
  padding: 0.5em 1em;
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 100ms;
  outline: 5px solid transparent; }
  .psv-tooltip-content {
    color: white;
    font: 14px sans-serif;
    text-shadow: 0 1px #000; }
  .psv-tooltip-arrow {
    position: absolute;
    height: 0;
    width: 0;
    border: 7px solid transparent; }
  .psv-tooltip--bottom-center {
    box-shadow: 0 3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, -5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--bottom-center .psv-tooltip-arrow {
      border-bottom-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--center-left {
    box-shadow: -3px 0 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(5px, 0, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--center-left .psv-tooltip-arrow {
      border-left-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--top-center {
    box-shadow: 0 -3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, 5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--top-center .psv-tooltip-arrow {
      border-top-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--center-right {
    box-shadow: 3px 0 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(-5px, 0, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--center-right .psv-tooltip-arrow {
      border-right-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--bottom-left {
    box-shadow: -3px 3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, -5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--bottom-left .psv-tooltip-arrow {
      border-bottom-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--bottom-right {
    box-shadow: 3px 3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, -5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--bottom-right .psv-tooltip-arrow {
      border-bottom-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--top-left {
    box-shadow: -3px -3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, 5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--top-left .psv-tooltip-arrow {
      border-top-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--top-right {
    box-shadow: 3px -3px 0 rgba(90, 90, 90, 0.7);
    transform: translate3d(0, 5px, 0);
    transition-property: opacity, transform; }
    .psv-tooltip--top-right .psv-tooltip-arrow {
      border-top-color: rgba(61, 61, 61, 0.8); }
  .psv-tooltip--visible {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition-duration: 100ms; }
